<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-edutrain-dashboard">
    <lxp-main-header :show-title="true" :show-breadcrumb="true" :show-excel-download="true">
      <template v-slot:action>
        <!-- main-header > header-tab -->
        <div class="header-tab">
          <nav class="kb-nav-round-tabs">
            <ul class="kb-nav-list">
              <li v-for="(menu, idx) in selfStudyMenus" class="kb-nav-item" :key="idx">
                <a href="javascript:" class="kb-nav-link" :class="{'is-active': selectedMenu === menu.id}" @click="handleMenuClick(menu)">
                  <span class="kb-nav-text">{{ menu.name }}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- main-header > header-snb -->
        <HistoryYearSelector v-model="yearStr" :show-select-all="true" :yearCnt="6"/>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <router-view v-if="selectedMenu === 'knowledge-e'">
        <HistoryKnowledgeE :selected-year="yearStr" />
      </router-view>
      <router-view v-else-if="selectedMenu === 'group-learning'">
        <HistoryGroupLearning :selected-year="yearStr" />
      </router-view>
      <router-view v-if="selectedMenu === 'cloud-easy'">
        <HistoryCloudEasy :selected-year="yearStr" />
      </router-view>
    </div>
    <!-- //main-content -->
  </main>
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import {computed, ref} from 'vue';
import HistoryKnowledgeE from '@/views/pages/history/HistoryKnowledgeE';
import HistoryCloudEasy from '@/views/pages/history/HistoryCloudEasy';
import HistoryGroupLearning from '@/views/pages/history/HistoryGroupLearning';
import {useRoute, useRouter} from 'vue-router';

export default {
  name: 'HistoryPrep',
  components: {HistoryGroupLearning, HistoryCloudEasy, HistoryKnowledgeE, HistoryYearSelector, LxpMainHeader},
  setup() {
    const route = useRoute();
    const router = useRouter();

    const selfStudyMenus = [
      {
        name: '지식e러닝',
        id: 'knowledge-e',
        path: '/history/knowledge-e',
      },
      {
        name: '그룹러닝',
        id: 'group-learning',
        path: '/history/group-learning',
      },
      {
        name: '클라우드 easy',
        id: 'cloud-easy',
        path: '/history/cloud-easy',
      },
    ];

    const selectedMenu = computed(() => {
      if (route.path.indexOf('group-learning') > -1) {
        return 'group-learning';
      } else if(route.path.indexOf('cloud-easy') > -1) {
        return 'cloud-easy';
      } else {
        return 'knowledge-e';
      }
    });

    const handleMenuClick = (menu) => {
      router.replace(menu.path);
    };

    const yearStr = ref(new Date().getFullYear());

    return {
      selfStudyMenus,
      selectedMenu,
      yearStr,
      handleMenuClick
    };
  },
};
</script>