<template>
  <!-- content-section -->
  <section class="content-section section-status">
    <div class="roundedbox-wrap roundedbox-low">
      <!-- roundedbox -->
      <div class="roundedbox" v-for="(item,idx) in summary" :key="idx">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{ item.studyToNm }}</h5>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">
            {{ checkFormat(item.val, item.valTp) }}
          </span>
        </div>
      </div>
    </div>
  </section>
  <!-- content-section:학습 콘텐츠 카테고리 -->
  <section class="content-section section-chart section-divider">
    <header class="section-header">
      <h4 class="title">학습 콘텐츠 카테고리</h4>
    </header>
    <!-- chart-wrap -->
    <div class="chart-wrap">
      <!-- chart -->
      <ToastPieChart />
      <!-- chart-details -->
      <div class="chart-details">
        <div class="chart-rank">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:45px;">
                <col style="width:auto;">
                <col style="width:auto;">
              </colgroup>
              <tbody>
              <tr>
                <th><strong class="th-title text-primary rank">1</strong></th>
                <td><strong class="td-text">가계여신</strong></td>
                <td class="text-end"><strong class="td-text text-muted">49%</strong></td>
              </tr>
              <tr>
                <th><strong class="th-title rank">2</strong></th>
                <td><strong class="td-text">외환</strong></td>
                <td class="text-end"><strong class="td-text text-muted">27%</strong></td>
              </tr>
              <tr>
                <th><strong class="th-title rank">3</strong></th>
                <td><strong class="td-text">펀드</strong></td>
                <td class="text-end"><strong class="td-text text-muted">22%</strong></td>
              </tr>
              <tr>
                <th><strong class="th-title rank">4</strong></th>
                <td><strong class="td-text">CRM</strong></td>
                <td class="text-end"><strong class="td-text text-muted">15%</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- //chart-wrap -->
  </section>
  <!-- content-section:학습 콘텐츠 유형 -->
  <section class="content-section section-chart section-divider">
    <header class="section-header">
      <h4 class="title">학습 콘텐츠 유형</h4>
      <div class="header-column">
        <div class="chart-labels">
          <div class="label"><i class="label-mark mark-primary"></i><span class="label-text">데모</span></div>
          <div class="label"><i class="label-mark mark-secondary"></i><span class="label-text">실습</span></div>
        </div>
      </div>
    </header>
    <!-- chart-wrap -->
    <div class="chart-wrap">
      <!-- chart -->
      <ToastColumnChart :categories="categories" :series="series" :options="options"/>
    </div>
    <!-- //chart-wrap -->
    <div class="learning-status-wrap">
      <div class="list-top">
        <div class="top-column align-self-end">
          <p class="title text-muted">총 {{ paging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <form @submit.prevent>
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="검색"/>
                <button class="kb-form-search-btn"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
        <table>
          <colgroup>
            <col style="width:65px"/>
            <col style="width:80px"/>
            <col style="width:auto"/>
            <col style="width:140px"/>
            <col style="width:105px"/>
            <col style="width:105px"/>
            <col style="width:105px"/>
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">카테고리</span></th>
            <th><span class="th-title">구분</span></th>
            <th><span class="th-title">콘텐츠명</span></th>
            <th><span class="th-title">최종 학습일자</span></th>
            <th><span class="th-title">학습시간</span></th>
            <th><span class="th-title">마일리지</span></th>
            <th><span class="th-title">학습완료</span></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item,idx) in cloudeasy" :key="idx">
              <td><span class="text">{{ item.lrnFldNm }}</span></td>
              <td><span class="text">{{ item.gb }}</span></td>
              <td>
                <div class="text-truncate"><strong class="text">{{ item.cttTitle }}</strong></div>
              </td>
              <td><span class="text">{{ item.endLrnDt }}</span></td>
              <td><span class="text">{{ item.lrnTime }}</span></td>
              <td><span class="text">{{ item.mlg }}</span></td>
              <td><strong class="text" :class="{ 'text-primary' : item.cmptnYn == '완료', 'text-red' : item.cmptnYn == '미완료' }">{{ item.cmptnYn }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
    </div>
  </section>
</template>
<script>
import {
  checkFormat,
  getCheckItems,
  getPaging,
  initPaging,
  setParamsByQueryString,
  summarizeAll,
} from '@/assets/js/util';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {onBeforeMount, onMounted, reactive, ref, toRef, watch} from 'vue';
import {ACT_GET_CLOUD_EASY_SUMMARY_LIST} from '@/store/modules/history/history';
import {ACT_GET_CLOUD_EASY_LIST} from '@/store/modules/prep/prep';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import ToastPieChart from '@/components/chart/ToastPieChart';
import ToastColumnChart from '@/components/chart/ToastColumnChart';

export default {
  name: 'HistoryCloudEasy',
  components: {ToastColumnChart, ToastPieChart, CommonPaginationFront},
  props: {
    selectedYear: [String, Number]
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const yearStr = toRef(props, 'selectedYear');
    const params = reactive({});

    const summary = ref([]);
    const cloudeasy = ref([]);

    const getSummary = () => {
      store.dispatch(`history/${ACT_GET_CLOUD_EASY_SUMMARY_LIST}`, {
        yearStr: yearStr.value
      }).then(res => {
        summary.value = getCheckItems(res);
        paging.value = getPaging(res);

        if (yearStr.value.length === 0) {
          summary.value = summarizeAll(summary.value, 'studyToIdStr');
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const getCloudEasyList = () => {
      store.dispatch(`history/${ACT_GET_CLOUD_EASY_LIST}`, {
        ...params,
        yearStr: yearStr.value,
        ...paging.value
      }).then(res => {
        cloudeasy.value = getCheckItems(res);
        paging.value = getPaging(res);
      }).catch(e => {
        console.error(e);
      });
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      // if (params.testName) query.testName = params.testName;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const categories = ref([
        '수신',
        '외환',
        '가계여신',
        '펀드',
        '기업여신'
    ]);

    const series = ref([
      {
        name: '데모',
        data: [2.7, 2, 3.5,1.3,4]
      },{
        name: '실습',
        data: [3,2.4,4.7,0.5,3]
      }
    ]);

    const options = ref({
      chart: { width: 1191, height: 271 },
      xAxis: {
        pointOnColumn: false,
        // title: { text: 'Month' }
      },
      // yAxis: { title: 'Temperature (Celsius)' },
      theme: {
        series: {
          areaOpacity: 0.7,
          colors: ['#ffcc00', '#e8e8e8'],
          barWidth: 30
        }
      },
      exportMenu: {
        visible: false
      },
      usageStatistics: false
    });

    watch(() => yearStr.value, () => {
      paging.value = initPaging(route);
      getSummary();
      getCloudEasyList();
    });

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HistoryCloudEasy', paging, params, true);
      getSummary();
      getCloudEasyList();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HistoryCloudEasy', paging, params, true); // 새로고침을 위한 작업
    });

    onMounted(() => {
      getSummary();
      getCloudEasyList();
    });

    return {
      params,
      yearStr,
      summary,
      cloudeasy,
      paging,
      pagingFunc,
      clickSearch,
      checkFormat,
      categories,
      series,
      options
    }
  }
};
</script>