<template>
  <div class="chart chart-line" ref="line"></div>
</template>

<script>
import '@toast-ui/chart/dist/toastui-chart.min.css';
import {onMounted, reactive, ref} from 'vue';
import {LineChart} from '@toast-ui/chart';

export default {
  name: "ToastLineChart",
  props: {
    categories: Array,
    series: Array,
    options: {
      type: Object,
      default: () => {
        return {
          chart: {width: 1197, height: 362},
          xAxis: {
            pointOnColumn: false,
            // title: { text: 'Month' }
          },
          // yAxis: { title: 'Temperature (Celsius)' },
          theme: {
            series: {
              areaOpacity: 0.7,
              colors: ['#ffcc00', '#e8e8e8'],
            },
          },
          exportMenu: {
            visible: false,
          },
          lang: {
            noData: '😭 데이터가 없습니다.'
          },
          usageStatistics: false
        }
      }
    }
  },
  setup(props){
    const line = ref(null);
    const chart = ref(null);
    const data = reactive({
      categories: props.categories,
      series: props.series
    });
    const options = reactive(props.options);

    onMounted(()=>{
      const el = line.value;
      chart.value = new LineChart({el, data, options});
    });

    return {
      line
    }
  }
}
</script>