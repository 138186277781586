<template>
  <div class="chart chart-line" ref="area"></div>
</template>
<script>

import {AreaChart} from "@toast-ui/chart";
import {onMounted, ref} from "vue";

export default {
  name: "ToastAreaChart",
  props: {
    categories: Array,
    series: Array
  },
  setup(props){
    const area = ref(null);
    const chart = ref(null);

    const data =  {
      categories: props.categories,
      series: props.series
    }

    const options = {
      chart: { width: 1200, height: 400 },
      xAxis: {
        pointOnColumn: false,
        // title: { text: 'Month' }
      },
      // yAxis: { title: 'Temperature (Celsius)' },
      theme: {
        series: {
          areaOpacity: 0.7,
          colors: ['#ffcc00', '#e8e8e8'],
        }
      },
      exportMenu: {
        visible: false
      },
      usageStatistics: false
    };

    onMounted(()=>{
      const el = area.value;
      chart.value = new AreaChart({el, data, options});
    });

    return {
      area,
    }
  }
}
</script>
