<template>
  <!-- content-section -->
  <section class="content-section section-status">
    <div class="roundedbox-wrap roundedbox-low">
      <!-- roundedbox -->
      <div class="roundedbox" v-for="(item,idx) in summary" :key="idx">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{ item.studyToNm }}</h5>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ checkFormat(item.val, item.valTp) }}</span>
        </div>
      </div>
    </div>
  </section>
  <!-- content-section:스터디 - 월별 참여 횟수 -->
  <section class="content-section section-chart section-divider">
    <header class="section-header">
      <h4 class="title">스터디 - 월별 참여 횟수</h4>
    </header>
    <!-- chart-wrap -->
    <div class="chart-wrap">
      <!-- chart -->
      <ToastPieChart />
    </div>
    <!-- //chart-wrap -->
    <div class="learning-status-wrap">
      <div class="list-top">
        <div class="top-column align-self-end">
          <p class="title text-muted">총 {{ paging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <form @submit.prevent>
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="검색"/>
                <button class="kb-form-search-btn"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
        <table>
          <colgroup>
            <col style="width:60px">
            <col style="width:180px">
            <col style="width:auto">
            <col style="width:350px">
            <col style="width:90px">
            <col style="width:90px">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">분야</span></th>
            <th><span class="th-title">그룹명</span></th>
            <th><span class="th-title">학습주제</span></th>
            <th><span class="th-title">모임날짜</span></th>
            <th><span class="th-title">출석</span></th>
            <th><span class="th-title">한줄평</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in studies" :key="idx">
            <td><span class="text">{{ item.lrnFldNm }}</span></td>
            <td><span class="text">{{ item.studyToNm }}</span></td>
            <td>
              <div class="text-truncate"><strong class="text">{{ item.val }}</strong></div>
            </td>
            <td><span class="text">{{ item.eduBgngDt }} - {{ item.eduEndDt }}</span></td>
            <td><strong class="text">{{ item.atendYn }}</strong></td>
            <td><strong class="text">{{ item.cmntYn }}</strong></td>
          </tr>
          </tbody>
        </table>
      </div>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
    </div>
  </section>
</template>
<script>
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {onBeforeMount, onMounted, reactive, ref, toRef, watch} from 'vue';
import {
  checkFormat,
  getCheckItems,
  getPaging,
  initPaging,
  setParamsByQueryString,
  summarizeAll,
} from '@/assets/js/util';
import {ACT_GET_STUDY_GROUP_LIST, ACT_GET_STUDY_GROUP_SUMMARY_LIST} from '@/store/modules/history/history';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import ToastPieChart from '@/components/chart/ToastPieChart';

export default {
  name: 'HistoryGroupLearning',
  components: {ToastPieChart, CommonPaginationFront},
  props: {
    selectedYear: [String, Number]
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const yearStr = toRef(props, 'selectedYear');
    const params = reactive({});

    const summary = ref([]);
    const studies = ref([]);

    const getSummary = async () => {
      await store.dispatch(`history/${ACT_GET_STUDY_GROUP_SUMMARY_LIST}`, {
        yearStr: yearStr.value
      }).then(res => {
        summary.value = getCheckItems(res);
        paging.value = getPaging(res);

        if (yearStr.value.length === 0) {
          summary.value = summarizeAll(summary.value, 'studyToIdStr');
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const getStudyGroupList = async () => {
      await store.dispatch(`history/${ACT_GET_STUDY_GROUP_LIST}`, {
        ...params,
        yearStr: yearStr.value,
        ...paging.value
      }).then(res => {
        studies.value = getCheckItems(res);
        paging.value = getPaging(res);
      }).catch(e => {
        console.error(e);
      });
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      // if (params.testName) query.testName = params.testName;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    watch(() => yearStr.value, () => {
      paging.value = initPaging(route);
      getSummary();
      getStudyGroupList();
    });

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HistoryGroupLearning', paging, params, true);
      getStudyGroupList();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HistoryGroupLearning', paging, params, true); // 새로고침을 위한 작업
    });

    onMounted(() => {
      getSummary();
      getStudyGroupList();
    });

    return {
      params,
      yearStr,
      summary,
      studies,
      paging,
      pagingFunc,
      clickSearch,
      checkFormat
    }
  }
};
</script>