<template>
  <!-- content-section -->
  <section class="content-section section-status">
    <div class="roundedbox-wrap roundedbox-low">
      <!-- roundedbox -->
      <div class="roundedbox" v-for="(item, idx) in summary" :key="idx">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{ item.knoweToNm }}</h5>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{ checkFormat(item.val, item.valTp) }}</span>
        </div>
      </div>
    </div>
  </section>
  <!-- content-section:지식e 평가 월별 점수 -->
  <section class="content-section section-chart section-divider">
    <header class="section-header">
      <h4 class="title">지식e 평가 월별 점수</h4>
    </header>
    <!-- chart-wrap -->
    <div class="chart-wrap">
      <!-- chart -->
      <ToastLineChart :data="chartData.line.data" :options="chartData.line.options"/>
    </div>
    <!-- //chart-wrap -->
  </section>
  <!-- content-section:지식e 평가 점수 분포도 -->
  <section class="content-section section-chart section-divider">
    <header class="section-header">
      <h4 class="title">지식e 평가 점수 분포도</h4>
    </header>
    <!-- chart-wrap -->
    <div class="chart-wrap">
      <!-- chart -->
      <ToastAreaChart :categories="chartData.area.categories" :series="chartData.area.series" />
    </div>
    <!-- //chart-wrap -->
    <div class="learning-status-wrap">
      <div class="list-top">
        <div class="top-column align-self-end">
          <p class="title text-muted">총 {{ paging.totalCount }}건</p>
        </div>
        <div class="top-column">
          <div class="kb-form-search">
            <div class="kb-form-search-field">
              <input type="text" class="kb-form-search-input" placeholder="검색"
                     @keyup.enter="clickSearch"
                     v-model="params.evlTyNm"
              />
              <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
        <table>
          <colgroup>
            <col style="width:60px">
            <col style="width:100px">
            <col style="width:auto">
            <col style="width:100px">
            <col style="width:220px">
            <col style="width:90px">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">평가유형</span></th>
            <th><span class="th-title">평가종류</span></th>
            <th><span class="th-title">평가명</span></th>
            <th><span class="th-title"></span></th>
            <th><span class="th-title">응시날짜</span></th>
            <th><span class="th-title">점수</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in knowledge" :key="idx">
            <td><span class="text">{{ item.evlTyNm }}</span></td> <!-- 평가 유형 -->
            <td><span class="text">{{ item.evlKindNm }}</span></td> <!-- 평가 종류 -->
            <td><strong class="text">{{ item.lrnEvlNm }}</strong></td> <!-- 평가명 -->
            <td></td> <!-- <td><span class="text">{{ item.evlDivNm }}</span></td> &lt;!&ndash; 평가 구분 &ndash;&gt;-->
            <td><span class="text">{{ item.evlBgngDt }}-{{ item.evlEndDt }}</span></td> <!-- 응시 날짜 -->
            <td><strong class="text">{{ item.evlScore }}점</strong></td> <!-- 점수 -->
          </tr>
          </tbody>
        </table>
      </div>

      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </div>
  </section>
</template>
<script>
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {ACT_GET_KNOWLEDGE_LIST, ACT_GET_KNOWLEDGE_SUMMARY_LIST} from '@/store/modules/history/history';
import {
  checkFormat,
  getCheckItems,
  getPaging,
  initPaging,
  setParamsByQueryString,
  summarizeAll,
} from '@/assets/js/util';
import {onBeforeMount, onMounted, reactive, ref, toRef, watch} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import ToastLineChart from '@/components/chart/ToastLineChart';
import ToastAreaChart from '@/components/chart/ToastAreaChart';

export default {
  name: 'HistoryKnowledgeE',
  components: {ToastAreaChart, ToastLineChart, CommonPaginationFront},
  props: {
    selectedYear: [String, Number],
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const yearStr = toRef(props, 'selectedYear');
    const params = reactive({
      evlTyNm: '',
    });

    const summary = ref([]);
    const knowledge = ref([]);

    const getSummary = async () => {
      await store.dispatch(`history/${ACT_GET_KNOWLEDGE_SUMMARY_LIST}`, {
        yearStr: yearStr.value,
      }).then(res => {
        summary.value = getCheckItems(res);
        paging.value = getPaging(res);

        if (yearStr.value.length === 0) {
          summary.value = summarizeAll(summary.value, 'knoweToIdStr');
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const getKnowledgeList = () => {
      store.dispatch(`history/${ACT_GET_KNOWLEDGE_LIST}`, {
        ...params,
        yearStr: yearStr.value,
        ...paging.value,
      }).then(res => {
        knowledge.value = getCheckItems(res);
        paging.value = getPaging(res);
      }).catch(e => {
        console.error(e);
      });
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo, year: yearStr.value};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (params.evlTyNm) query.evlTyNm = params.evlTyNm;
      if (yearStr.value) query.year = yearStr.value;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const chartData = {
      line: {
        data: {
          categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월'],
          series: [ {
              name: '나의 점수',
              data: [20, 40, 10, 60, 55, 70, 60, 80, 85, 100],
            }],
        },
        options: {
          chart: {width: 1208, height: 330},
          xAxis: {
            pointOnColumn: false,
            // title: { text: 'Month' }
          },
          yAxis: {
            scale: {
              max: 100
            }
          },
          theme: {
            series: {
              areaOpacity: 0.7,
              colors: ['#ffcc00', '#e8e8e8'],
            },
          },
          exportMenu: {
            visible: false,
          },
          legend: {
            visible: false
          }
        },
      },
      area: {
        categories: ['00','10','20','30','40','50','60','70','80','90','100'],
        series: [{
          name: '평가 점수',
          data: ['00','1.4','1.8','2.7','5.7','6.8','9.9', '5', '2.2','1', '0']
        }]
      },
      usageStatistics: false
    };

    watch(() => yearStr.value, () => {
      getSummary();
      getKnowledgeList();
    });

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HistoryKnowledgeE', paging, params, true);
      getSummary();
      getKnowledgeList();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HistoryKnowledgeE', paging, params, true); // 새로고침을 위한 작업
    });

    onMounted(() => {
      getSummary();
      getKnowledgeList();
    });

    return {
      params,
      yearStr,
      summary,
      knowledge,
      paging,
      pagingFunc,
      clickSearch,
      checkFormat,
      chartData,
    };
  },
};
</script>